export default {
    "fr": [
        {
            "ques": "Qu'est-ce que le mégaplieur de loterie Mega Millions ?",
            "ans": "Le Megaplier est une option optionnelle unique au Mega Millions, qui multiplie les gains hors jackpot.  Lors de l'achat d'un billet Mega Millions, vous pouvez choisir d'ajouter cette option à votre billet.  Ainsi, vous pouvez multiplier vos gains hors jackpot par 2, 3, 4 ou 5 fois, selon le Megaplier tiré pour ce tirage.  Par exemple, si vous gagnez un prix de deuxième catégorie de 1 000 000 $ et ajoutez le Megaplier, qui par exemple a été tiré à 3, alors votre prix serait de 3 000 000 $.  Sélectionnez l'option multiplicateur Megaplier en cochant la case sous la grille lors de l'achat de billets de loterie Mega Millions en ligne avec Lotto Amigo pour maximiser vos gains hors jackpot !"
        },
        {
            "ques": "Qu'est-ce que le PowerBall Lottery Power Play ?",
            "ans": "Le Power Play est une option optionnelle unique au PowerBall, qui multiplie les gains hors jackpot.  Lors de l'achat d'un billet PowerBall, vous pouvez choisir d'ajouter cette option à votre billet.  Ainsi, vous pouvez multiplier vos gains hors jackpot par 2, 3, 4 ou 5 fois, selon le Power Play tiré pour ce tirage.  Par exemple, si vous gagnez un prix de deuxième catégorie de 1 000 000 $ et ajoutez le Power Play, qui par exemple a été tiré à 3, alors votre prix serait de 3 000 000 $.  Sélectionnez l'option multiplicateur Power Play en cochant la case sous la grille lors de l'achat de billets de loterie PowerBall en ligne avec Lotto Amigo pour maximiser vos gains hors jackpot !"
        },
        {
            "ques": "Quelles loteries ont les meilleures chances de jackpot ?",
            "ans": "Les chances de gagner le jackpot Powerball sont de 1 sur 292 millions.  Avant que Powerball n'introduise un changement de règle en octobre 2015, les chances étaient de 1 sur 175 millions.  Les fabricants de loterie réalisent évidemment qu'avec des cotes plus faibles, il y a moins de chances de gagner et cela crée des jackpots plus importants.  Cela augmente les ventes de billets.  Mega Millions, en comparaison, offre une chance sur 302,6 millions de remporter le jackpot."
        },
        {
            "ques": "A qui appartient votre billet ?",
            "ans": "Une fois vos numéros choisis, un représentant de Lotto Amigo achètera directement cette entrée en notre nom.  Il est possible de voir une copie du billet en utilisant le service de visualisation des billets dans votre compte.<br>Le ticket est scanné et identifié, ce qui garantit son existence et que vous en êtes le propriétaire.  Le joueur détient bien le ticket pour participer à la loterie de son choix et c'est prouvé.  Le vrai ticket est conservé dans un coffre-fort pour récupérer vos gains."
        },
        {
            "ques": "Est-il possible de jouer au Powerball en dehors des États-Unis ?",
            "ans": "Lorsque vous découvrez que Powerball ou MegaMillions ont un jackpot de plusieurs centaines de millions de dollars, vous vous demandez probablement s'il est possible de participer depuis l'étranger, surtout si vous n'êtes pas citoyen américain.  Heureusement, c'est tout à fait possible !<br>Selon les règles officielles du Powerball et du MegaMillions, n'importe qui peut « acheter un billet Powerball ou MegaMillions, participer au jeu et gagner des prix ».  Et plus que cela, le site Web de Powerball et MegaMillions indique clairement que \"vous n'avez pas besoin d'être citoyen ou résident américain pour jouer\".<br>Vous n'avez probablement pas le temps ou l'argent pour vous rendre aux États-Unis pour acheter des billets pour le Powerball.  C'est là qu'intervient Lotto Amigo.<br>Lorsque vous achetez des billets de loterie officiels via Lotto Amigo, nos agents locaux aux États-Unis achètent des billets en votre nom."
        },
        {
            "ques": "Quelles sont les chances de gagner ?",
            "ans": "Les chances de gagner le jackpot Powerball ne semblent pas être en votre faveur (1 sur 292 millions !) Et Powerball n'a certainement pas les meilleures chances de jackpot, mais quelqu'un finit toujours par gagner l'incroyable prix de loterie.  D'autant plus que vous pouvez gagner des prix Powerball sans toucher le jackpot, car la loterie compte neuf catégories de prix au total.  Les chances de gagner un prix Powerball sont de 1 sur 24,9, que le jackpot du jeu soit de 40 millions de dollars ou de 1 milliard de dollars."
        },
        {
            "ques": "Quel est le plus gros jackpot de loterie jamais tiré aux États-Unis ?",
            "ans": "Le record du plus gros jackpot de loterie a été établi en janvier 2016 lorsque la US Powerball Lottery a décroché un jackpot de 1,58 milliard de dollars, un prix époustouflant qui a été partagé entre trois billets gagnants.  Chacun de ces billets a reçu un paiement forfaitaire de 327,8 millions de dollars.  En octobre 2018, ce record a été quasiment battu par le Mega Millions, avec un jackpot de 1,537 milliard de wons par un seul ticket.<br>Étant donné que les dix meilleurs jackpots de loterie de tous les temps ont été établis par deux loteries américaines - le Powerball et le USA Mega Millions Lotto - la première conclusion indique que ce sont les loteries les plus intéressantes.  Leurs énormes jackpots, ainsi que les gros prix annexes, attirent non seulement tous les médias, mais aussi de nombreux joueurs.  Avec un tel niveau de concurrence, on pourrait éventuellement conclure que le seul obstacle pour gagner un énorme jackpot est d'acheter un billet Powerball ou MegaMillions et heureusement pour vous, Lotto Amigo peut le faire pour vous."
        },
        {
            "ques": "Comment acheter mon propre billet ?",
            "ans": "Afin de vous offrir une expérience sécuritaire et optimale, nous faisons affaire avec les meilleurs fournisseurs de services de cartes de crédit.  Suivez les instructions à l'écran pour terminer la transaction."
        },
        {
            "ques": "Comment régler mes achats de billets ?",
            "ans": "Connectez-vous à votre compte Lotto Amigo.  Choisissez la loterie à laquelle vous voulez jouer.  Remplissez vos billets, sélectionnez vos options de jeu et cliquez sur JOUER.<br>Lorsque vous réglez votre achat, le système déduira automatiquement les frais de participation de votre carte de crédit ou du solde de votre compte Lotto Amigo.<br>Confirmez l'achat de votre billet.  Vous recevrez une notification automatique par e-mail lorsque votre commande sera terminée.<br>S'il s'agit de votre premier achat sur notre site, vous devez d'abord terminer le processus d'inscription complet et associer un mode de paiement à votre compte afin de commencer à jouer."
        },
        {
            "ques": "Comment saurai-je si je gagne ?",
            "ans": "Lorsque vous jouez à la loterie en ligne avec Lotto Amigo, vous n'avez pas besoin de vérifier vous-même les résultats du tirage de la loterie pour voir si vous avez gagné des prix.  Nous vous informerons à chaque fois que vous gagnez avec un e-mail automatique.  Dans le même temps, votre compte privé Lotto Amigo sera mis à jour et vous pourrez voir les détails du tirage et vos gains sur votre compte."
        },
        {
            "ques": "Comment collecter vos gains en ligne sur Lotto Amigo ?",
            "ans": "Les lots sont transférés sur votre compte joueur sécurisé après la diffusion des résultats et peu de temps après la réception du lot par l'opérateur de loterie officiel.Veuillez noter que les gros gains, les jackpots et les prix de deuxième niveau devront être récupérés en personne aux États-Unis, avec un agent Lotto Amigo via un vol sponsorisé !  Tous les prix américains de plus de 600 $ sont soumis à la taxe locale.  Pour plus d'informations sur les taxes sur les gains, veuillez visiter la page d'informations sur la loterie de Floride à l'adresse <a href='https://www.flalottery.com/'>https://www.flalottery.com/</a>."
        },
        {
            "ques": "Comment fonctionne Lotto Amigo ?",
            "ans": "Lotto Amigo est un service d'achat de billets de loterie en ligne.  Lorsque vous choisissez de jouer aux deux plus gros tirages de loterie des États-Unis sur notre site Web, nos agents achètent physiquement des billets de loterie officiels en votre nom.<br>Lorsque vous jouez à la loterie en ligne sur Lotto Amigo, vous sélectionnez les numéros auxquels vous souhaitez jouer.  Vos billets sont scannés et téléchargés sur votre compte personnel avant le tirage.  Lorsque vous gagnez, vous êtes immédiatement averti et les gains sont rapidement transférés sur votre compte, sauf s'il s'agit du Jackpot."
        },
        {
            "ques": "Lotto Amigo est-il affilié à un organisme gouvernemental officiel ?",
            "ans": "Lotto Amigo agit en tant que service indépendant d'achat de billets de loterie.  Nous n'opérons pas sous l'autorité d'un organisme de loterie officiel et n'exploitons aucune loterie.  Le concept est simple, tout comme le processus.  Lotto Amigo comble le fossé entre vous et vos loteries préférées, vous donnant la chance de gagner les plus gros jackpots.  Nous sommes un service d'achat et de consignation indépendant qui vous permet de participer légalement aux loteries américaines depuis le confort de votre salon dans votre pays d'origine."
        },
        {
            "ques": "Qui peut utiliser les services de Lotto Amigo ?",
            "ans": "Lotto Amigo accueille des clients du monde entier et s'adresse aux personnes de plus de dix-huit (18) ans.  Les personnes de moins de 18 ans ne sont pas autorisées à créer un compte et/ou à utiliser nos services."
        },
        {
            "ques": "Puis-je utiliser les services Lotto Amigo depuis mon pays ?",
            "ans": "Les services d'achat de billets de loterie en ligne sont disponibles pour les clients du monde entier.  Nous permettons aux fans de loterie de jouer aux meilleures loteries du monde, peu importe où ils vivent.  Actuellement, certaines restrictions de paiement et réglementaires sont en place qui empêchent les joueurs basés dans certains pays de profiter de nos services tels que la Corée du Nord, l'Iran et le Pakistan."
        },
        {
            "ques": "Mes informations personnelles et de paiement sont-elles protégées sur le site ?",
            "ans": "Absolument.  Vos informations personnelles et de paiement sont cryptées et accessibles uniquement avec un nom d'utilisateur et un mot de passe.  Tout le trafic entre votre navigateur Web et notre site est entièrement sécurisé et vous pouvez utiliser les services de Lotto Amigo en toute sécurité et sans souci avec toute la protection offerte par votre carte de crédit."
        },
        {
            "ques": "Quels sont les avantages d'acheter des billets de loterie avec Lotto Amigo ?",
            "ans": "Avec Lotto Amigo, vous pouvez acheter des billets de loterie officiels légalement, sur une plateforme fiable et sécurisée depuis votre ordinateur personnel ou appareil mobile.  Avec Lotto Amigo, vous ne perdrez JAMAIS vos billets de loterie grâce à la numérisation automatisée des billets et au stockage sécurisé.<br>De plus, vous pouvez compter sur nos services pour valider rapidement vos billets et transférer rapidement vos gains sur votre compte."
        },
        {
            "ques": "Qu'est-ce que le billet de groupe gratuit ?",
            "ans": "Chez Lotto Amigo nous sommes conscients que tout le monde ne peut pas se permettre d'acheter un ticket de loterie mais comme nous croyons fermement que tout le monde devrait pouvoir avoir au moins UNE chance de gagner, nous offrons pour chaque tirage un ticket gratuit à nos abonnés.<br>Il vous suffit de vous inscrire pour faire partie du groupe gratuitement, sans obligation d'achat.  Si l'un de nos billets de groupe gagnait un gros lot, nous partagerions le montant total du prix à parts égales avec tous les participants dûment inscrits."
        },
        {
            "ques": "Y a-t-il un nombre maximum de participants dans les groupes libres ?",
            "ans": "Pour des raisons logistiques, nous limitons le nombre de participants à nos groupes gratuits à 2000 personnes.  Le billet de groupe gratuit est publié à midi le lendemain de chaque tirage et reste ouvert jusqu'à la date limite fixée ou jusqu'à ce que le groupe soit complet."
        },
        {
            "ques": "Comment commencer à jouer ?",
            "ans": "La mise en route est un processus simple et rapide en 3 étapes.<br>Le premier pas<br>Inscrivez-vous en cliquant sur le bouton S'inscrire ou sur le bouton Lecture.  A cette étape, vous devrez lister votre email et choisir un mot de passe.  Assurez-vous de choisir un mot de passe fort pour protéger votre compte.<br>Deuxième étape<br>Remplissez le champ des informations personnelles supplémentaires.  Il vous sera demandé de fournir votre nom, date de naissance, adresse complète et numéro de téléphone.  La SEULE information dont nous avons besoin est celle énumérée ci-dessus.  Nous avons besoin de ces informations car nous sommes tenus de faire preuve de diligence raisonnable envers nos clients et, bien sûr, de pouvoir vous joindre le plus rapidement possible si vous gagnez un jackpot ou un prix secondaire substantiel.  Si tel est le cas, nous vous contacterons directement par téléphone de manière sécurisée.  Et enfin, vous devez confirmer que vous avez plus de 18 ans et que vous avez lu et accepté nos conditions d'utilisation.<br>Troisième étape<br>Associez une carte de crédit à votre compte afin de pouvoir financer l'achat de nos services.  Vous verrez les modes de paiement disponibles dans votre région et vous pourrez choisir celui que vous préférez.  Remplissez les informations requises pour votre mode de paiement spécifique.<br>Après avoir effectué ces 3 étapes, vous êtes prêt à participer légalement aux différents tirages grâce aux services de Lotto Amigo."
        },
        {
            "ques": "Dois-je divulguer mes données personnelles si je gagne ?",
            "ans": "Si vous gagnez un lot de loterie sur des billets achetés sur Lotto Amigo, des informations telles que votre prénom, la première lettre de votre nom et votre pays de résidence seront automatiquement publiées dans la section Nos Gagnants de la page d'accueil de notre site.  Si vous gagnez un jackpot de loterie, les exigences de l'organisation de loterie officielle de Floride s'appliquent."
        },
    ],
    "en": [
        {
            "ques": "What is the Mega Millions Lottery Megaplier?",
            "ans": "The Megaplier is an optional option unique to the Mega Millions, which multiplies the non-jackpot winnings. When purchasing a Mega Millions ticket, you can choose to add this option to your ticket. Thus, you can multiply your non-jackpot winnings by 2, 3, 4 or 5 times, depending on the Megaplier drawn for that draw. For example, if you won a second category prize of $ 1,000,000 and added the Megaplier, which for example was drawn at 3, then your prize would be $ 3,000,000. Select the Megaplier multiplier option by checking the box below the grid when purchasing Mega Millions lottery tickets online with Lotto Amigo to maximize your non-jackpot winnings!"
        },
        {
            "ques": "What is the PowerBall Lottery Power Play?",
            "ans": "The Power Play is an optional option unique to the PowerBall, which multiplies the non-jackpot winnings. When purchasing a PowerBall ticket, you can choose to add this option to your ticket. Thus, you can multiply your non-jackpot winnings by 2, 3, 4 or 5 times, depending on the Power Play drawn for that draw. For example, if you won a second category prize of $ 1,000,000 and added the Power Play, which for example was drawn at 3, then your prize would be $ 3,000,000. Select the Power Play multiplier option by checking the box below the grid when purchasing PowerBall lottery tickets online with Lotto Amigo to maximize your non-jackpot winnings!"
        },
        {
            "ques": "Which lotteries have the best jackpot odds?",
            "ans": "The odds of winning the Powerball jackpot are 1 in 292 million. Before Powerball introduced a rule change in October 2015, the odds were 1 in 175 million. Lottery makers obviously realize that with lower odds there is less chance of winning and this creates bigger jackpots. This increases ticket sales. Mega Millions, in comparison, offers a 1 in 302.6 million chance of hitting the jackpot."
        },
        {
            "ques": "Who owns your ticket?",
            "ans": "Once your numbers have been chosen, a Lotto Amigo representative will directly purchase this entry on our behalf. It is possible to see a copy of the ticket using the ticket viewing service in your account.<br>The ticket is scanned and identified, which guarantees its existence and that you are the owner of it. The player does hold the ticket to participate in the lottery of their choice and it is proven. The real ticket is kept in a safe to collect your winnings."
        },
        {
            "ques": "Is it possible to play Powerball outside the United States?",
            "ans": "When you find that Powerball or MegaMillions has a jackpot worth hundreds of millions of dollars, you are probably wondering if it is possible to participate from abroad, especially if you are not a US citizen. Fortunately, it is quite possible!<br> According to the official Powerball and MegaMillions rules, anyone can “buy a Powerball or MegaMillions ticket, participate in the game and win prizes”. And more than that, the Powerball and MegaMillions website clearly states that \"you don't have to be a US citizen or resident to play.\"<br> You probably don't have the time or the money to travel to the United States to buy Powerball tickets. This is where Lotto Amigo comes in.<br> When you purchase official lottery tickets through Lotto Amigo, our local agents in the United States will purchase tickets on your behalf."
        },
        {
            "ques": "What are the odds of winning?",
            "ans": "The odds of winning the Powerball jackpot don't seem to be in your favor (1 in 292 million!) And Powerball certainly doesn't have the best jackpot odds, but someone always ends up winning the incredible lottery prize. Especially since you can win Powerball prizes without hitting the jackpot, as the lottery has nine prize categories in total. The odds of winning a Powerball Prize are 1 in 24.9, regardless of whether the in-game jackpot is $ 40 million or $ 1 billion."
        },
        {
            "ques": "What is the biggest lottery jackpot ever drawn in the US?",
            "ans": "The record for the biggest lottery jackpot was set in January 2016 when the US Powerball Lottery staked out a jackpot of $ 1.58 billion, a mind-boggling prize that was split between three winning tickets. Each of those tickets received a lump sum payment of $ 327.8 million. In October 2018, this record was almost broken by the Mega Millions, with a jackpot of 1.537 billion won by a single ticket.<br> Seeing that the ten best lottery jackpots of all time were established by two American lotteries - the Powerball and the USA Mega Millions Lotto - the first conclusion indicates that these are the most interesting lotteries. Their huge jackpots, as well as big side prizes, attract not only all media, but also many players. With such a level of competition, one could eventually conclude that the only barrier to winning a huge jackpot is buying a Powerball or a MegaMillions ticket and luckily for you, Lotto Amigo can do that for you."
        },
        {
            "ques": "How do I buy my own ticket?",
            "ans": "In order to provide you with a safe and optimal experience, we do business with the best credit card service providers. Follow the on-screen instructions to complete the transaction."
        },
        {
            "ques": "How do I pay for my ticket purchases?",
            "ans": "Log into your Lotto Amigo account. Choose the lottery you want to play. Fill out your tickets, select your play options and click PLAY.<br> When you pay for your purchase, the system will automatically deduct the participation fee from your credit card or from your Lotto Amigo account balance.<br> Confirm your ticket purchase. You will receive an automatic email notification when your order is complete.<br> If this is your first purchase from our site, you must first complete the full registration process and associate a payment method with your account in order to start playing."
        },
        {
            "ques": "How will I know if I win?",
            "ans": "When playing the lottery online with Lotto Amigo, you don't have to check lottery draw results yourself to see if you've won any prizes. We will notify you every time you win with an automatic email. At the same time, your private Lotto Amigo account will be updated and you will be able to see the details of the draw and your winning in your account."
        },
        {
            "ques": "How to collect your Online winnings on Lotto Amigo?",
            "ans": "The prizes are transferred to your secure player account following the broadcast of the results and shortly after receipt of the prize by the official lottery operator.<br> Please note that large wins, jackpots and second-tier prizes will need to be picked up in person in the USA, with a Lotto Amigo agent via a sponsored flight! All USA prizes over 600$ are subject to local tax. For more information on taxes on winnings, please visit the Florida Lottery info page at <a href=\"https://www.flalottery.com/\" target=\"_blank\">https://www.flalottery.com/</a>."
        },
        {
            "ques": "How does Lotto Amigo work?",
            "ans": "Lotto Amigo is an online lottery ticket purchase service. When you choose to play the two largest US lottery draws on our website, our agents will physically purchase official lottery tickets on your behalf.<br> When you play the lottery online at Lotto Amigo, you select the numbers you want to play. Your tickets are scanned and uploaded to your personal account before the draw. When you win you are immediately notified and the winnings are quickly transferred to your account unless it’s the Jackpot."
        },
        {
            "ques": "Is Lotto Amigo affiliated with an official government body?",
            "ans": "Lotto Amigo acts as an independent lottery ticket buying service. We do not operate under the authority of an official lottery body and do not operate any lotteries. The concept is simple, as is the process. Lotto Amigo bridges the gap between you and your favorite lotteries, giving you the chance to win the biggest jackpots. We are an independent buying and consignment service that allows you to participate in US lotteries legally from the comfort of your living room in your home country."
        },
        {
            "ques": "Who can use the services of Lotto Amigo?",
            "ans": "Lotto Amigo welcomes customers from all over the world and is intended for people over the age of eighteen (18). People under the age of 18 are not allowed to create an account and / or use our services."
        },
        {
            "ques": "Can I use Lotto Amigo services from my country?",
            "ans": "Online lottery ticket buying services are available to customers around the world. We allow lottery fans to play the best lotteries in the world, no matter where they live. Currently, certain payment and regulatory restrictions are in place which prevent players based in certain countries from taking advantage of our services such as North Korea, Iran and Pakistan."
        },
        {
            "ques": "Are my personal and payment information protected on the site?",
            "ans": "Absolutely. Your personal and payment information is encrypted and accessible only with a username and password. All traffic between your web browser and our site is completely secure and you can use Lotto Amigo's services safely and worry-free with all the protection that your credit card provides."
        },
        {
            "ques": "What are the advantages of buying lottery tickets with Lotto Amigo?",
            "ans": "With Lotto Amigo, you can buy official lottery tickets legally, on a reliable and secure platform from your personal computer or mobile device. With Lotto Amigo, you will NEVER lose your lottery tickets thanks to automated ticket scanning and secure storage.<br> In addition, you can count on our services to quickly validate your tickets and to quickly transfer your winnings to your account."
        },
        {
            "ques": "What is the free group ticket?",
            "ans": "At Lotto Amigo we are aware that not everyone can afford to buy a lottery ticket but as we firmly believe that everyone should be able to have at least ONE chance to win, we offer for every draw a free ticket to our subscribers.<br> All you have to do is register to be part of the group for free, with no obligation to purchase. If one of our group tickets were to win a major jackpot, we would split the total prize amount equally with all duly registered participants."
        },
        {
            "ques": "Is there a maximum number of participants in the free groups?",
            "ans": "For logistical reasons, we limit the number of participants in our free groups to 2000 people. The free group ticket is published at noon the day after each draw and remains open until the set deadline or until the group is full."
        },
        {
            "ques": "How to start playing?",
            "ans": "Getting started is a quick and easy 3-step process.<br><br> The first step<br> Register by clicking on the Register button or the Play button. At this step, you will need to list your email and choose a password. Make sure you choose a strong password to protect your account.<br><br> Second step<br> Fill in the additional personal information field. You will be asked to provide your name, date of birth, full address and telephone number. The ONLY information we need is what is listed above. We need this information because we are required to exercise due diligence towards our customers and of course, to be able to reach you as quickly as possible if you win a jackpot or a substantial secondary prize. If this is the case, we will contact you directly by phone in a secure manner. And finally, you must confirm that you are over 18 years old and that you have read and accepted our terms of service.<br><br> Third step<br> Associate a credit card with your account so that you can finance the purchase of our services. You will see the payment methods available in your region and you can choose the one you prefer. Fill in the information required for your specific payment method.<br><br> After having carried out these 3 steps, you are ready to legally participate in the various draws thanks to the services of Lotto Amigo."
        },
        {
            "ques": "Do I have to disclose my personal data if I win?",
            "ans": "If you win a lottery prize on tickets purchased on Lotto Amigo, information such as your first name, first letter of your last name and country of residence will automatically be published in the Our Winners section of the home page of our site. If you win a lottery jackpot, the requirements of the official lottery organization in Florida apply."
        },
    ]
}
