<template>
  <div class="emb-faq-wrap">
    <div class="d-flex justify-center text-h4 ma-6 pt-3 font-weight-bold">
      {{ $t("message.faqPage.title") }}
    </div>
    <div class="d-flex justify-center text-subtitle-1">
      {{ $t("message.faqPage.desc") }}
    </div>
    <div class="emb-faq-content section-gap pb-0">
      <div class="container">
        <div class="faq">
          <div class="section-gap pt-1">
            <v-expansion-panels>
              <v-expansion-panel v-for="(question, key) in faq" :key="key">
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 font-weight-bold">
                    {{ question.ques }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p v-html="question.ans" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
      <div class="subscribe-block subscribe-bg section-gap text-center">
        <div class="container">
          <v-layout row warp align-center justify-center>
            <v-flex sm12 xl6 lg6 md6>
              <div class="text-h5 font-weight-bold mb-6">
                {{ $t("message.faqPage.noAnswerQuestion") }}
              </div>
              <div class="text-subtitle-1 mb-4">
                {{ $t("message.faqPage.noAnswerDesc") }}
              </div>
              <v-btn class="accent" large to="/contact">
                {{ $t("message.faqPage.noAnswerBtn") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import api from "Api";
import faqData from "Assets/data/faqData";

export default {
  watch: {
    "$i18n.locale": function () {
      this.faq = this.faqData[this.$i18n.locale];
    },
  },
  data() {
    return {
      faq: [],
      faqData,
    };
  },
  mounted() {
    this.getFaq();
  },
  methods: {
    getFaq() {
      this.faq = this.faqData[this.$i18n.locale];
    },
  },
};
</script>